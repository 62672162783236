import React from 'react';
import descJSON from './descriptions.json';

export interface panelValue {
  dt: string;
  minutes: number;
  value: number;
  valtype: string;
  placename: string;
}

export interface panelValues {
  mainvalue: panelValue;
  subvalue: panelValue;
  thirdvalue: panelValue;
  commentline: string;
  themepostfix: string;
  sw_state: boolean;
}

const valueDiv = (value: number, valstyle: string, unit: string) => {
  const intval = Math.trunc(value);
  const fracval = Math.trunc(Math.abs(value - intval)*10);
  var msign = '';
  if ((value < 0) && (value > -1)) 
    msign = '-';

return (
    <div className={valstyle}>{msign}{intval}.<div className='fracpart'>{fracval} {unit}</div></div>
  )
}

const smallvalueDiv = (caption: string, value: number, valstyle: string, unit: string) => {
  const intval = Math.trunc(value);
//  const fracval = Math.trunc(Math.abs(value - intval)*10);
return (
    <div className={valstyle}>{caption} {intval} {unit}</div>
  )
}

const commentDiv = (caption: string, valstyle: string) => {
return (
    <div className={valstyle}>{caption}</div>
  )
}

export function SensorPanelItem (props) {
    const pvalues: panelValues = props.pvalues;
    const small: boolean = props.small;
    console.log(pvalues);
    const maindesc = descJSON.places[pvalues.mainvalue.placename];
    const subdesc = descJSON.places[pvalues.subvalue.placename];

    let icon = '';
    let themepostfix='';
    if (small) 
      themepostfix='_small';
      
    let thirdline: JSX.Element = <></>;
    if (pvalues.thirdvalue.placename !== '') {
      //const thirddesc = descJSON.places[pvalues.thirdvalue.placename];
      thirdline = smallvalueDiv(descJSON.types[pvalues.thirdvalue.valtype].caption+':', pvalues.thirdvalue.value, `sensorvalue2${themepostfix}`,descJSON.types[pvalues.thirdvalue.valtype].unit);
    }
    else if (pvalues.commentline !== '') {
      console.log(pvalues.commentline);
      thirdline= commentDiv(pvalues.commentline, `sensorvalue2${themepostfix}`); 
    }

    if (pvalues.mainvalue.placename === 'garage') {
      if (pvalues.sw_state) 
        icon = maindesc.icon2
      else
        icon = maindesc.icon
    }
    else 
      icon = maindesc.icon;


    if (maindesc && subdesc) {
      return (
          <div className={`sensorpanel${themepostfix}`}>
          <div className={`sensorimage${themepostfix}`}><img src={icon} alt={pvalues.mainvalue.placename} title={pvalues.mainvalue.dt}></img>{maindesc.caption}</div>
          <div className={`sensorvalues${themepostfix}`}>
            {valueDiv(pvalues.mainvalue.value, `sensorvalue${themepostfix}`, descJSON.types[pvalues.mainvalue.valtype].unit)}
            {smallvalueDiv(descJSON.types[pvalues.subvalue.valtype].caption+': ', pvalues.subvalue.value, `sensorvalue2${themepostfix}`, descJSON.types[pvalues.subvalue.valtype].unit)}
            {thirdline}
        </div></div>
      )
    }
}


export function Sensorgroup (props) {
  if (props.sensors) {
    const placedesc = descJSON.places[props.sensors[0].shs_place];
    const typedesc1 = descJSON.types[props.sensors[0].shs_type];
    const typedesc2 = descJSON.types[props.sensors[1].shs_type];
    const intval1 = Math.trunc(parseFloat(props.sensors[0].shs_value));
    var msign = '';
    if ((parseFloat(props.sensors[0].shs_value) < 0) && (parseFloat(props.sensors[0].shs_value) > -1)) 
      msign = '-';
        
    const fracval1 = Math.trunc(Math.abs(props.sensors[0].shs_value - intval1)*10);
    const intval2 = Math.trunc(parseFloat(props.sensors[1].shs_value));
 //   const fracval2 = Math.trunc(Math.abs(props.sensors[1].shs_value - intval2)*10);
    let themepostfix='';
    if (props.size==='1') 
      themepostfix='_small';
      
    if (placedesc && typedesc1 && typedesc2) {
      return (
          <div className={`sensorpanel${themepostfix}`}>
          <div className={`sensorimage${themepostfix}`}><img src={placedesc.icon} alt={placedesc.caption} title={props.sensors[0].shs_dt}></img>{placedesc.caption}</div>
          <div className={`sensorvalues${themepostfix}`}>
            <div className={`sensorvalue${themepostfix}`}>{msign}{intval1}.<div className='fracpart'>{fracval1} {typedesc1.unit}</div></div>
            <div className={`sensorvalue2${themepostfix}`}><div className='fracpart'>{typedesc2.caption}: {intval2} {typedesc2.unit}</div></div>
        </div></div>
      )
    }
  }
}
